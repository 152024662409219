/**
 *
 * top-menu
 *
 */
Mmg.addShortcode(
  "top_menu",
  ".sc-top-menu",
  (el, model, $, selector, className) => {
    // Widget Declarations
    const $this = $(el);
    const $subMenu = $this.find(`${selector}__sub-menu`);
    const $menuLinks = $this.find(`${selector}__nav-link`);
    const $regions = $this.find(`#${className}__regions`);
    const $topCities = $this.find(`#${className}__top-cities`);
    const regionsTemplate = $this.find(`${selector}__template-regions`).html();
    const topCitiesTemplate = $this.find(`${selector}__template-top_cities`).html();
    const $backButton = $this.find(`${selector}__sub-menu-back`);
    const $searchButton = $this.find(`#searchButton`);
    const $inputSearch = $this.find(`#searchInput`);
    const $chatLink = $this.find(`#chatLink`);

    /**
     * Initialize the widget
     */
    (function init() {
      addEvents();
      initCallbacks();
    })();

    /**
     * Main Event dispatcher method
     */
    function addEvents() {
      // Handles static tabs in the menu
      $this.find("[data-tab]").each(function () {
        $(this).on("click", this, tabHandler);
      });

      // Opens regions rendered server side
      $this.on("click", `${selector}__nav-link-regions`, handleRegions);

      // Rerender regions client side
      $this.on("click", `${selector}__regions-link`, handleRegionsClick);
      $this.on("click", `${selector}__sub-menu-back`, handleRegionsClick);

      // Close all tabs
      $this.on("click", `${selector}__sub-menu-close`, closeSubMenu);
      $(window).on('click', closeSubMenu);


      // TODO: Use delegated event handlers ie. bind to $this
      /* Event on 'chat with us' link */
      $chatLink.on("click", handleChat);

      /* Event for submit seach button */
      $searchButton.on("click", handleSearch);

      /* 'Enter' submit input values */
      $inputSearch.on('keyup', function(event){
        if(event.key == "Enter") handleSearch();
      });

    }

    /**
     * Handle tab events
     */
    function tabHandler(event) {
      
      event.preventDefault();
      event.stopPropagation();
      clearSelected();

      // open sub-menu container
      $subMenu.addClass("open");

      // display tab
      let $target = $(event.currentTarget);
      let tabName = $target.data("tab");
      $target.addClass("selected");
      $(`#${className}__${tabName}`).show();
    }

    /**
     * Load API Data
     *
     * @param {string} url
     * @param {object} params
     */
    function load(url, params) {
      $subMenu.addClass("loading");
      $.get(url, params).done(renderRegions);
    }

    /**
     * Render view
     *
     * @param {object} data
     */
    function renderRegions(data) {
      if (data) {
        data._block = className;
        data.context = model.context;
        
        $regions
          .html(Mustache.render(regionsTemplate, data))
          .updateBackButton(data)
          .renderTopCities(data)
          .removeLoadingClass();
      }
      
    }

    /**
     * Handle region clicks
     *
     * @param {object} event
     */
    function handleRegions(event) {
      event.preventDefault();
      event.stopPropagation();
      
      clearSelected();

      // load main links when main link is triggered
      handleRegionsClick(event);

      let $target = $(event.currentTarget);
      $target.addClass("selected");

      // open sub-menu container
      $subMenu.addClass("open");
      $regions.show();
      $topCities.show();
    }

    /**
     * Handle region clicks
     *
     * @param {object} event
     */
    function handleRegionsClick(event) {
      event.preventDefault();
      event.stopPropagation();

      let $target = $(event.currentTarget);
      let targetType = $target.data("page_type") ?? "";

      const cityTemplates = ["c-in_city", "c-near_city"];
      if (cityTemplates.includes(targetType)) {
        window.location.href = $target.attr("href");
        return;
      }

      let params = {};
      params.page_id = $target.data("page_id");

      let url = model.regions_api_url;

      load(url, params);
    }

    /**
     * Add custom methods for callbacks
     */
    function initCallbacks() {
      // sets context for the back button
      $regions.updateBackButton = function (data) {
        if (data.parent_page_type == "world") {
          $backButton.hide();
        } else {
          $backButton.show();
        }

        $backButton.data({
          page_id: data.parent_page_id,
          page_type: data.parent_page_type,
        });

        return $regions;
      };

      $regions.renderTopCities = function (data) {
        $topCities
          .html(Mustache.render(topCitiesTemplate, data));
        
        return $regions;
      }

      // Removes loading class from submenu
      $regions.removeLoadingClass = function () {
      $subMenu.removeClass("loading");

        return $regions;
      };
    }

    /**
     * Close sub menu
     */
    function closeSubMenu() {
      clearSelected();
      $subMenu.removeClass("open");
    }

    /**
     * Removes selected class from main navigation
     */
    function clearSelected() {
      $menuLinks.removeClass("selected");

      // clear amy opened tabs
      $this.find(`${selector}__tab`).hide();
      $regions.hide();
      $topCities.hide();
      $backButton.hide();
    }

    /**
     * Handle search modal events
     */
    function handleSearch(){
      return window.location.assign('https://www.beachbound.com/search/?q=' + encodeURIComponent(document.getElementById('searchInput').value));
    };


    /**
    * Handle search modal events
    */
    function handleChat(){
      if (typeof(icPatronChat) !== 'undefined') {icPatronChat.setStateWindowToggle();}else {window.open('https://home-c13.incontact.com/incontact/chatclient/chatclient.aspx?poc=ee24d9ce-4213-4174-b35d-113db38d048a&bu=4594472', 'icPatronChatWin', 'location=no,height=630,menubar=no,status-no,width=410', true);}
    };

  }
);
