/**
 * Create a decorator over daterangepicker.
 * https://github.com/dangrossman/bootstrap-daterangepicker
 *
 * Features:
 * Display two months by default
 *
 * @param string|element el The selector or element of the widget containing
 *                          the input fields and mustache templates.
 * @param object datePickerSettings Settings
 * @param object $ jQuery
 * @param string selector Selector for daterangepicker.
 */
function MmgDatepickerDecorator(el, datePickerSettings, $, selector) {

  const $this = $( el ),
    $datePickerInput = $this.find(selector),
    dp = $datePickerInput.daterangepicker(datePickerSettings).data("daterangepicker"),
    startDate = $datePickerInput.attr('data-date-start'),
    endDate = $datePickerInput.attr('data-date-end'),
    $startDate = $this.find(startDate),
    $endDate = $this.find(endDate);

  // Global selector is necessary as library renders calendar outside of main node
  const $calendar = $('.daterangepicker');
  const dateFormat = datePickerSettings.dateFormat || 'MM-DD-YYYY';

  /**
   * Initialize the widget
   */
  (function init(){
    addEvents();
  })();

  /**
  * Main Event dispatcher method
  */
  function addEvents() {
    $startDate.on('click', (e) => {
      dp.show();
      $datePickerInput.trigger('clickStartDate.daterangepicker');
    });

    $endDate.on('click', (e) => {
      dp.show();
      $datePickerInput.trigger('clickEndDate.daterangepicker');
    });

    $datePickerInput.on('setStartDate.daterangepicker', (e, value) => {
      $startDate.val(value);
    });

    $datePickerInput.on('setEndDate.daterangepicker', (e, value) => {
      $endDate.val(value);
    });

    // Daterangepicker mousedown event in library uses e.stopPropogation
    $calendar.find('.calendar').on('mousedown.daterangepicker', 'td.available', () => {
      $datePickerInput.trigger('clickDateAvailable.daterangepicker', dp);
    });

    // After dates are selected change inputs
    $datePickerInput.on('apply.daterangepicker', (e, picker) => {
      $startDate.val(picker.startDate.format(dateFormat));
      $endDate.val(picker.endDate ? picker.endDate.format(dateFormat) : null);
      $datePickerInput.trigger('applied.daterangepicker', [$startDate, $endDate]);
    });

  }

  return dp;
}
