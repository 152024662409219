/**
 *
 * Property list widget
 *
 */
Mmg.addShortcode(

  'properties',
  '.sc-properties',

  /**
   * @param {Element} el The shortcode's DOM element
   * @param {object} model The data model passed to the shortcode instance in data-json attribute
   * @param {jQuery} $ jQuery
   * @param {string} selector The shortcode selector that was passed to addShortcode
   * @param {string} className The name of the shortcode class
   */
  (el, model, $, selector, className) => {

  // Widget Declarations
  const $this = $(el);
  const onChange = _.throttle(handleChange, 1000);
  const form = new Mmg.Form($this, {visibleCheckboxCount: 8});
  // Format "is_package" parameter for APIs to represent the three possible states properly.
  const isPackage = 'is_package' in model ? model.is_package ? '1' : '0' : '';

  let $theme;
  let $price;
  let $rating;
  let $amenities;
  let currentRequest;

  // Configuration
  let page = 1;
  const visibleCheckboxCount = 8;

  const $filters = $this.find(`${selector}__filters`);
  const $filterForm = $this.find(`${selector}__filter-form`);
  const $filterBody = $this.find(`${selector}__filter-body`);
  const $filterToggle = $this.find(`${selector}__filter-toggle`);
  const $sort = $this.find(`${selector}__sort-select`);
  const $items = $this.find(`${selector}__properties-list`);
  const $pagination = $this.find(`${selector}__pagination`);
  const $clearFilters = $this.find(`${selector}__clear-filters`);
  const $resultCount = $this.find(`${selector}__result-count`);
  const $toggleAllInclusive = $this.find(`${selector}__toggle-all-inclusive`);

  const resultCountTemplate = $this.find(`${selector}__template-result-count`).html();
  const propertyTemplate = $this.find(`${selector}__template-property`).html();
  const hiddenClass = 'd-none';

  const pristineFilters = {
    theme: null,
    price: null,
    rating: null,
    amenities: null,
  };


  /**
   * Initialize the widget
   */
  (function init(){
    $filterForm.on('change', onChange);
    $clearFilters.on('click', clearFilters);
    $filterToggle.on('click', handleToggle);
    $toggleAllInclusive.on('click', handleToggleAllInclusive);

    $this.on(
      'click',
      '.form-checkbox-group-more',
      (event) => form.showAllCheckboxes(event)
    );
    $this.on(
      'change',
      `${selector}__sort-select`,
      () => $filterForm.trigger('change', ['sort'])
    );
    $this.on('click', `${selector}__pagination-button`, handlePagination);

    $this.on('click', `${selector}__button-show-filters`, showFilterForm);
    $this.on('click', `${selector}__button-hide-filters`, hideFilterForm);

    $this.on('click', `${selector}__button-show-map`, showMap)

    update();
    $filterForm.trigger('change');
  })();


  /**
   * Trigger the 'show.map' event with current filter configuration
   * @param {*} event
   */
  function showMap(event) {
    event.preventDefault();
    $(window).trigger('show.map', {
      filters: getFilters(),
      region_id: model.region_id,
      mode: model.mode,
      is_package: isPackage,
    });
  }


  /**
   * Show the modal filter form and disable form events
   */
   function showFilterForm(event) {

    event.preventDefault();
    $filterForm.off('change');

    $filters
      .removeClass('hidden-sm-down')
      .addClass('show-mobile')
      .css({
        left: - $(window).width(),
        right: $(window).width(),
      })
      .animate({
        left: 0,
        right: 0,
      }, 'fast');
  }


  /**
   * Hide the modal filter form, enable and trigger form events
   */
  function hideFilterForm(event) {

    event.preventDefault();
    $filterForm.on('change', handleChange).trigger('change');

    $filters.animate({
      left: - $(window).width(),
      right: $(window).width(),
    }, 'fast', () => {
      $filters
      .css({
        left: "",
        right: "",
      })
      .addClass('hidden-sm-down')
      .removeClass('show-mobile');
    });
  }


  /**
   * Toggle the All-Inclusive filter
   * @param {*} event
   */
  function handleToggleAllInclusive(event) {
    const $trigger = $(event.currentTarget);
    const $allInclusiveCheckbox = $this.find('.form-check-input[value="All-Inclusive"]');

    $trigger.toggleClass('active');

    if ($trigger.hasClass('active')) {
      $allInclusiveCheckbox.prop('checked', true);
    } else {
      $allInclusiveCheckbox.prop('checked', false);
    }

    $filterForm.trigger('change');
  }


  /**
   * Toggle visibility of form inputs
   * @param {*} el
   */
  function handleToggle(event) {
    const $el = $(event.currentTarget);
    event.preventDefault();
    if ($filterBody.is(':visible')) {
      $el.addClass('closed');
      $filterBody.hide();
    } else {
      $el.removeClass('closed');
      $filterBody.show();
    }
  }


  /**
   * Update page and trigger filter change
   */
  function handlePagination() {
    page += 1;
    $filterForm.trigger('change', ['page']);
  }


  /**
   * Update form elements after client side render
   */
  function update(){
    $theme = $this.find("input[name^=theme]");
    $price = $this.find("input[name^=price]");
    $rating = $this.find("input[name^=rating]");
    $amenities = $this.find("input[name^=amenities]");

    form.initSliders();
    form.initCheckboxGroups();
  };


  /**
   * Handle filter and input value updates
   * @param {*} event
   */
  function handleChange(event, context) {
    const sort = $sort.filter(':visible').val();
    const filters = getFilters();

    event.preventDefault();
    $pagination.prop('disabled', true);
    form.storedValues = Object.keys(filters);

    if(filters.theme.includes("All-Inclusive")) {
      $toggleAllInclusive.addClass('active');
    } else {
      $toggleAllInclusive.removeClass('active');
    }

    if ( _.isEqual(filters, pristineFilters) ) {
      $clearFilters.addClass(hiddenClass);
    } else {
      $clearFilters.removeClass(hiddenClass);
    }

    // reset page if this is not a pagination event
    if (context !== 'page') {
      page = 1;
    }

    load(filters, page, sort);
  }


  /**
   * Get values for all filters
   * @returns object
   */
  function getFilters() {
    return {
      theme: form.checkboxValues($theme),
      price: form.inputValues($price),
      rating: form.checkboxValues($rating),
      amenities: form.checkboxValues($amenities),
    };
  }


  /**
   * Remove all filter values.
   * @param {*} event
   */
  function clearFilters(event) {
    event.preventDefault();
    clearCheckboxes($theme);
    clearCheckboxes($rating);
    clearCheckboxes($amenities);
    resetNumberRange($price)
    $filterForm.trigger('change');
  }


  /**
   * Uncheck all checkbox values
   * @param {*} $input
   */
  function clearCheckboxes($input) {
    $input.each((index, input) => {
      $(input).prop('checked', false);
    });
  }


  /**
   * Reset number range values
   * @param {*} $input
   */
  function resetNumberRange($input) {
    const $group = $input.parents('.form-group');
    const $min = $group.find('.form-number-range-min');
    const $max = $group.find('.form-number-range-max');
    const min = Number($min.attr('min'));
    const max = Number($max.attr('max'));
    const slider = $group.find('.form__slider')[0];

    slider.noUiSlider.set([min, max]);
  }


  /**
   * Load API Data
   */
  function load(filters, page, sort) {
    const data = {
      filters,
      page,
      max: model.max,
      region_id: model.region_id,
      mode: model.mode,
      is_package: isPackage,
      theme: model.theme,
    };

    if (sort) {
      data.sort = sort.split(':')[0];
      data.sort_direction = sort.split(':')[1];
    }

    $items.addClass('loading')
    $filterBody.removeClass('ghost');

    currentRequest && currentRequest.abort();
    currentRequest = $.get(model.api_url, data)
      .done(render)
      .always(() => $items.add($filters).removeClass('loading'));
  }


  /**
   * Render the number of matching results
   * @param {*} data
   */
  function renderResultCount(data) {
    $resultCount.html(Mustache.render(resultCountTemplate, data));
  }


  /**
   * Render filter form
   * @param {object} data
   */
  function renderFilters(data) {

    if(data.total_result_count <= 1){
      $filterBody.addClass('ghost');
    }

    if(!data.options.theme.includes("All-Inclusive")) {
      $toggleAllInclusive.hide();
    }

    $filterForm.html([
      form.renderNumberRange($price, data.options),
      form.renderCheckboxGroup($theme, data.options),
      form.renderCheckboxGroup($rating, data.options),
      form.renderCheckboxGroup($amenities, data.options),
    ].join(''));
  }


  /**
   * Render all views
   * @param {object} data
   */
  function render(data) {
    renderItems(data);
    renderFilters(data);
    renderPagination(data);
    renderResultCount(data);
    update();
  }


  /**
   * Render pagination buttons
   * @param {object} data
   */
  function renderPagination(data) {
    if (data.result_count > $items.find(`${selector}__card`).length) {
      $pagination.prop('disabled', false).show()
    } else {
      $pagination.hide();
    }
  }


  /**
   * Render properties
   * @param {object} data
   */
  function renderItems(data) {
    if (page === 1) {
      $items.html("");
    }

    if(data.result_count > 0){
      $items.append(_.map(data.properties, (item, index) => {
        item._block = className;
        return Mustache.render(propertyTemplate, item);
      }));
      $this.removeClass('no-results');
    } else {
      $this.addClass('no-results');
    }
  }

});
