Alg0213 = window.Alg0213 || {};

Alg0213.BookingEngine = (function ($, options, moment) {

    let dateFormat = options.dateFormat || 'YYYY-MM-DD';

    const defaults = {
        tripLength: 3,
        departDateOffset: 14,
    };

    let postData = {
        gsVendor: options.vendorCode,
        currentCulture: 'en-US',
        gsdateformat: 'd',
        gsPackage: 'AH01',
        gsvacationtype: 'AH01',
        gsOrigin: '',
        gsDestination: '',
        gsLengthOfStay: '',
        gsPromotionCode: '',
        gsDepartureDate: '',
        gsReturnDate: '',
        gsNumberOfAdults: '2',
        gsNumberOfTravelers: '2',
        gsAge1: '',
        gsAge2: '',
        gsAge3: '',
        gsAge4: '',
        gsAge5: '',
        hoHotelName: '',
        gsPrefHotelCode: '',
        uiShoppingStep: '',
    };

    const userOriginDeferred = $.Deferred();


    (function init(){
        primeUserOrigin();
        setDefaults();
    })();


    /** 
     * Set postData default values that require computation
    */
    function setDefaults(){
        const {
            departDateOffset,
            tripLength,
        } = defaults;

        update({
            gsLengthOfStay: tripLength,
            gsDepartureDate: moment().add(departDateOffset, 'days').format(dateFormat),
            gsReturnDate: moment().add(departDateOffset + tripLength, 'days').format(dateFormat),
        });
    }


    /**
     * Prime the user origin so we don't have to 
     * do a lookup when the user submits the form.
     */
    function primeUserOrigin(){
        getUserOrigin().then(origin => setProperty('gsOrigin', origin.code));
    }


    /**
     * Get the origin airport nearest to the location lat/long.
     * @param {*} location 
     * @returns Deferred
     */
    function getOriginAirport(location){
        return $.get({
            url: options.airportApiUrl,
            data: location,
       });
    }


    /**
     * Get origin airport based on user location.
     * Look up user location, then look up the nearest airport.
     * Store the value in sessionStorage for better UX.
     * 
     * @returns Deferred
     */
    function getUserOrigin(){
        const storageKey = 'userOrigin';
        const userOrigin = sessionStorage.getItem(storageKey);
        let userGeoIP;

        if (userOrigin) {
            return userOriginDeferred.resolve(JSON.parse(userOrigin));
        }

        return Mmg.geoip()
            .then(geodata => {
                userGeoIP = geodata;
                return getOriginAirport(geodata.location)
            })
            .then(airports => airports.results[0])
            .then(origin => {
                origin = {...origin, location: userGeoIP.location};
                sessionStorage.setItem(storageKey, JSON.stringify(origin));
                userOriginDeferred.resolve(origin);
                return origin;
            });
    }

    
    /**
     * Set a single property
     * @param {*} property 
     * @param {*} value 
     */
    function setProperty(property, value) {
        postData[property] = value;
    }


    /**
     * Update postData with properties
     * @param {*} properties 
     */
    function update(properties) {
        postData = {
            ...postData,
            ...properties,
        };

        postData.uiShoppingStep = postData.gsPrefHotelCode ? 'HP' : '';
    }


    /**
     * Send postData via POST 
     * @param {*} data 
     */
    function post(data = {}) {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = options.url;

        update(data);
    
        for (const key in postData) {
          if (postData.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = postData[key];
            form.appendChild(hiddenField);
          }
        }
        
        document.body.appendChild(form);
        form.submit();
    }


    /**
     * Send postData via GET
     * @param {*} data 
     */
    function get(data = {}) {
        update(data);
        window.location = `${options.url}?${$.param(postData)}`;
    }


    /**
     * Public API
     */
    return {
        dateFormat,
        getUserOrigin: userOriginDeferred.promise,
        setProperty,
        update,
        post,
        get,
    }

})(jQuery, Alg0213BookingEngineOptions, moment);
