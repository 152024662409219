/* jshint laxcomma: true */
/* global _ */
window.ParsleyConfig = {
	// ### General

	// Default data-namespace for DOM API
	namespace: 'data-parsley-',

	// Supported inputs by default
	inputs: 'input, textarea, select',

	// Excluded inputs by default
	excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden]',

	// Stop validating field on highest priority failing constraint
	priorityEnabled: true,

	// ### Field only

	// identifier used to group together inputs (e.g. radio buttons...)
	multiple: null,

	// identifier (or array of identifiers) used to validate only a select group of inputs
	group: null,

	// ### UI
	// Enable\Disable error messages
	uiEnabled: true,

	// Key events threshold before validation
	validationThreshold: 3,

	// Focused field on form validation error. 'fist'|'last'|'none'
	focus: 'none',

	// `$.Event()` that will trigger validation. eg: `keyup`, `change`...
	trigger: 'change blur',

	// Class that would be added on every failing validation Parsley field
	errorClass: 'has-error',

	// Same for success validation
	successClass: 'has-success',

	// Return the `$element` that will receive these above success or error classes
	// Could also be (and given directly from DOM) a valid selector like `'#div'`
	classHandler: function (ParsleyField) {
		return ParsleyField.$element.parents('.form-group');
	},

	// Return the `$element` where errors will be appended
	// Could also be (and given directly from DOM) a valid selector like `'#div'`
	errorsContainer: function (ParsleyField) {
		return ParsleyField.$element.parents('form').find('.errors');
	},

	// ul elem that would receive errors' list
	errorsWrapper: '<ul class="parsley-errors-list"></ul>',

	// li elem that would receive error message
	errorTemplate: '<li></li>',

	// custom validator functions
	validators: {

		// total value of group must me greater than requirement
		groupmin: {
			fn: function (value, requirement) {
				
				// get the sum of the entire group
				// if group sum is less than requirement 
				// return false 
			},
			priority: 32
		},

		// Total number of travellers must be greater than 1 and less than 7
		travellers: {
			fn: function ( ) {
				
				
				var $errors = $( '.widget-booking-form .errors' ),
					$travellerFields = $('[data-parsley-' + this.name + ']'),
					reducer = function( memo, num ){ return memo + num; },

					// get the sum of all travellers
					sum = _.reduce( $.map( $travellerFields, function(item){
						return Number( $( item ).val() );
					}), reducer)
				;

				// Remove existing error artifacts
				$errors.find( '.error-travellers' ).remove();
				$travellerFields.each(function(){
					$( this ).parents( '.form-group' ).removeClass( ParsleyConfig.errorClass );
				});
				
				// if group sum is less than requirement 
				// return false 
				if( sum < 1 ){
					
					// Add the error message
					$('<div/>', { 
						'class': 'error-travellers',
						text: 'At least one traveller is required.'
					})
					.appendTo( $errors );

					// Highlight the entire group
					$travellerFields.each(function(){
						$( this ).parents( '.form-group' ).addClass( ParsleyConfig.errorClass );
					});

					return false;
				}
				
				return true;
			},
			priority: 32
		}
	}
};