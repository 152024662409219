/**
 *
 * Handle card behavior site-wide.
 *
 */
jQuery(function($){

  const $body = $('body');
  const selector = '.card';
  const selectorLinks = `${selector} a`;

  (function init(){
    addEvents();
  })();


  /**
   * Handle UI events
   */
  function addEvents() {
    $body.on('vclick', selectorLinks, handleLinkClick);
  }

  /**
   * Open hotel link clicks in new tab.
   */
  function handleLinkClick(event) {
    const $target = $(event.currentTarget);
    const url = $target.attr('href');
    const parsedUrl = new URL(url);
    if (parsedUrl.pathname === '/HotelById') {
      event.preventDefault();
      window.open(url, '_blank');
    }
  }
});
