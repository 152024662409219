/**
 *
 * Javascript behavior for map Property cards
 *
 */

Mmg.addShortcodeFilter(
  "map--property-card",
  ".sc-map--property-card",
  (el, model, $, selector, className) => {
    // widget vars
    let parentSelector = '.sc-map';

    const $this = $(el);
    const $card = $this.find(`${parentSelector}__card`);
    const $map = $this.find(`${parentSelector}__map`);
    const $cardContainer = $this.find(`${parentSelector}__card-container`);
    
    const templates = {
      'card': $this.find(`${parentSelector}__tpl-card`).html(),
    };
    
    let activeMarker;
    
    
    /**
     * Initialize the widget filter
     */
    (function init() {
      $this.data('filters', {
        processMarkers: processMarkers
      });

      $(window).on("show.map", loadMapModal);
		  $(window).on('shown.map', () => $this.removeClass("loading"));
    })();

    /**
     * Load map inside modal
     */
    function loadMapModal() {
      $('#modal-map').modal('show');
      $this.addClass("loading");
    }
    
    /**
     * Process markers for the map
     * @param {array} type
     * @param {object} location
     * @param {google.maps.Map} map
     * @param {object} bounds
     */
    function processMarkers(type, locations, map, bounds) {
      let anchorMmgOptions = Mmg['google-map-anchor-options']() || {};
      const anchorOptions = {
        url: 'https://maps.gstatic.com/mapfiles/transparent.png',
        size: new google.maps.Size(5, 5),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 31)
      };
      const markerIcon = $.extend({}, anchorOptions, anchorMmgOptions);

      const markers = $.map(locations, location => {
        const latLng = new google.maps.LatLng(
          location.latitude,
          location.longitude
        );
        
        let priceCeil = Math.ceil(location.price);
        let priceFormatted = new Intl.NumberFormat().format(priceCeil);
        let markerLabel = priceCeil <= 0 ? 'Check Rates' : '$' + priceFormatted;
        
        // Calculate z-index using latitude so markers don't overlap
        // doing a ceil in a latitude like 18.322785 and 18.322384 would result in the same number
        // So the value is multiplied and then divided to generate a sorter number
        // Using negative value so the highest markers lays behind each other
        // and also cluster markers
        // TODO look into how to address locations in the other side of the equator
        let zIndex = -Math.ceil(location.latitude * 1000);
        
        const marker = new Marker({
          map: map,
          position: latLng,
          icon: markerIcon,
          map_icon_label: `<span class="sc-map__marker" style="z-index:${zIndex};">${markerLabel}</span>`
        });
        
        addMarkerEvents(marker, location, map);

        bounds.extend(latLng);

        return marker;
      });

      //Add listener to the map to close cards when click anywhere
      map.addListener('click', () => hideCard(map));
      return markers;
    }

    /**
     * Controls to the marker
     * @param {object} marker The marker instance
     * @param {object} location The location associated with the marker
     * @param {google.maps.Map} map
     */
    function addMarkerEvents(marker, location, map) {
      marker.addListener('click', () => toggleCard(marker, location, map));
    }

    /**
     * Toggle Card
     * @param {object} marker The marker instance
     * @param {object} location The location associated with the marker
     * @param {google.maps.Map} map
     */
    function toggleCard(marker, location, map){
      if(activeMarker === marker){
        activeMarker = null;
        hideCard(map);
      } else {
        activeMarker = marker;

        showCard(marker, map);
        renderCard(location);
      }
    }

    /**
     * Show Card 
     * @param {object} marker The marker instance
     * @param {google.maps.Map} map
     */
    function showCard(marker, map){
      $card.empty();

      $map.one('transitionend', () => {
        google.maps.event.trigger(map, 'resize');
        map.panTo(marker.position);
        $map.off('transitionend');
      });

      if($map.hasClass('has-card')){
        $map.trigger('transitionend');
      }

      $cardContainer.addClass('is-visible');
      $map.addClass('has-card');
    }

    /**
     * Hide Card
     * @return {[type]} [description]
     */
    function hideCard(map){
      $cardContainer.removeClass('is-visible');
      $map.removeClass('has-card');

      $map.one('transitionend', () => {
        google.maps.event.trigger(map, 'resize');
      });
    }

    /**
     * Renders card
     * @param  {[type]} location [description]
     */
    function renderCard(location){
      $card.html(Mustache.render(
        templates.card,
        getCardModel(location)
      ));
    }

    /**
     * Get card model
     * @param  {[type]} location [description]
     * @return {[type]}          [description]
     */
    function getCardModel(location){
      return $.extend(location, model.card_model);
    }
    
});
