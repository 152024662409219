/**
 *
 * 
 *
 */
Mmg.addShortcode(

  /**
   * @\Mmg\NewsletterForm
   *
   * @param {string} shortcodeName The name of the shortcode
   * @param {string} selector The selector used to locate this shortcode in the DOM
   * @param {callable} callback The callback function applied to every element matching the selector
   */
  'newsletter-form',
  '.sc-newsletter-form',

  /**
   * @\Mmg\NewsletterForm
   *
   * Shortcode callback
   *
   * The shortcode callback receives the following parameters:
   *
   * @param {Element} el The shortcode's DOM element
   * @param {object} model The data model passed to the shortcode instance in data-json attribute
   * @param {jQuery} $ The callback function applied to every element matching the selector
   * @param {string} selector The shortcode selector that was passed to addShortcode
   * @param {string} className The name of the shortcode class
   */
  (el, model, $, selector, className) => {

    // Widget Declarations
    const $this = $(el);
    const $formGroup = $(`${selector}__newsletter-form-group`);
    const $emailInput = $(`${selector}__newsletter-input`);
    const $emailError = $(`${selector}__email-signup-error`);
    const $emailConfirm = $(`${selector}__email-signup-confirm`);

    const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    let emailValue;
    let subscribeData = {};
    

    /**
     * Initialize the widget
     */
    (function init() {
        $this.on("click", `${selector}__newsletter-submit`, formHandler);
    })();

    /**
     * Handle when form is submitted
     */
    function formHandler() {
        emailValue = $emailInput.val().trim();

        if (!regExp.test(emailValue)) {
            handleError('* Please enter a valid email address');
        }
        else {
            subscribeData['__QESEMAILADDRESSID'] = emailValue;
            subscribeData['__QESACTIONID'] = 'SUBMIT';
            subscribeData['__QESPAGENAMEID'] = 'footer';

            $.ajax({
                dataType: 'jsonp',
                url: model.url,
                data: subscribeData,
                jsonpCallback: 'QuickSignup',
                success: function (result) {
                    if ((result != undefined) && (result != null) && (result.DisplayThankYou == false)) {
                        if (result.IsDuplicate == true) {
                            handleError('* e-mail already registered.')    
                        }
                        else {
                            handleError(result.errors[0].error);
                        }

                    } else if ((result != undefined) && (result != null) && (result.DisplayThankYou == true)) {
                        handleSuccess(result.ThankYouText);
                    }
                },
                fail: function (result) {
                    handleError('Request failed please try again.');
                }
            });
        }
    }

    /**
     * Handles error message and failed requests
     */
    function handleError(error) {
        $emailError.append(error);
        $emailError.show();
        $formGroup.show();
        $emailConfirm.hide();
    }

    /**
     * Handles successful queries
     */
    function handleSuccess(message) {
        $formGroup.hide();
        $emailError.hide();
        $emailConfirm.show();
        $emailConfirm.append('<p>' + message + '</p>');
    }

});
