/**
 *
 * date
 *
 */
Mmg.addShortcode(
  "date",
  ".sc-date",
  (el, model, $, selector, className) => {
    // Widget Declarations
    const $this = $(el);

    /**
     * Initialize the widget
     */
    (function init() {
      updateDate();
    })();

    function updateDate() {
      if (model.render_at_client) {
        const momentFormat = phpToMoment(model.format);
        const formattedDate = moment().format(momentFormat);
        $this.text(formattedDate);
      }
    }

    function phpToMoment(str) {
      const replacements = {
        'd': 'DD',
        'D': 'ddd',
        'j': 'D',
        'l': 'dddd',
        'N': 'E',
        'S': 'o',
        'w': 'e',
        'z': 'DDD',
        'W': 'W',
        'F': 'MMMM',
        'm': 'MM',
        'M': 'MMM',
        'n': 'M',
        't': '', // no equivalent
        'L': '', // no equivalent
        'o': 'YYYY',
        'Y': 'YYYY',
        'y': 'YY',
        'a': 'a',
        'A': 'A',
        'B': '', // no equivalent
        'g': 'h',
        'G': 'H',
        'h': 'hh',
        'H': 'HH',
        'i': 'mm',
        's': 'ss',
        'u': 'SSS',
        'e': 'zz', // deprecated since Moment.js 1.6.0
        'I': '', // no equivalent
        'O': '', // no equivalent
        'P': '', // no equivalent
        'T': '', // no equivalent
        'Z': '', // no equivalent
        'c': '', // no equivalent
        'r': '', // no equivalent
        'U': 'X',
      };
      return str.split('').map(chr => chr in replacements ? replacements[chr] : chr).join('');
    }
  }
);
