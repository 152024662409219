/**
 *
 * Javascript controller for the ALG [booking_form] shortcode.
 *
 * TODO:
 * - validate max travelers 8
 * - layout
 * - mobile grid
 */
Mmg.addShortcode(

  /**
   *
   * @param {string} shortcodeName The name of the shortcode
   * @param {string} selector The selector used to locate this shortcode in the DOM
   * @param {callable} callback The callback function applied to every element matching the selector
   */
  'booking-form',
  '.sc-booking-form',

  /**
   *
   * Shortcode callback
   *
   * The shortcode callback receives the following parameters:
   *
   * @param {Element} el The shortcode's DOM element
   * @param {object} model The data model passed to the shortcode instance in data-json attribute
   * @param {jQuery} $ The callback function applied to every element matching the selector
   * @param {string} selector The shortcode selector that was passed to addShortcode
   * @param {string} className The name of the shortcode class
   */
  (el, model, $, selector, className) => {

  // Widget Declarations
  const $this = $(el);

  // Elements
  const $form = $this.find('form');
  const $formPlaceholder = $this.find(`${selector}__form-placeholder`);
  const $roomOptions = $this.find(`${selector}__room-options`);
  const $inputOrigin = $this.find(`${selector}__input-origin`);
  const $inputDestination = $this.find(`${selector}__input-destination`);
  const $departDate = $this.find(`${selector}__input-depart-date`);
  const $returnDate = $this.find(`${selector}__input-return-date`);
  const $buttonHotelFlight = $this.find('a[href="#hotel-flight"]');
  const $buttonHotelOnly = $this.find('a[href="#hotel-only"]');
  const $submit = $this.find('button[type="submit"]');
  const $buttonFlightOnly = $this.find('a[href="#flight-only"]');
  const $originColumn = $this.find(`${selector}__origin-column`);
  const $editBtn = $this.find(`${selector}__edit`);
  const $buttonGo = $this.find(`${selector}__btn-go`);

  // Templates
  const templateRoomOptions = $this.find('.tpl-room-options').html();
  const templateSelectRoomCount = $this.find('.tpl-select-room-count').html();
  const templateSelectAdultCount = $this.find('.tpl-select-adult-count').html();
  const templateSelectChildCount = $this.find('.tpl-select-child-count').html();
  const templateSelectChildAge = $this.find('.tpl-select-child-age').html();

  const dateFormat = model.dateFormat;
  const departDateOffset = model.departDateOffset;
  const tripLength = model.tripLength;
  const defaultStartDate = moment().add(departDateOffset, 'days');
  const defaultEndDate = moment().add(departDateOffset + tripLength, 'days');

  const packageType = {
    hotel: 'H01',
    flightHotel: 'AH01',
    flight:'A01',
  };

  // Defaults
  const defaultOptions = {
    roomCount: [
      {label: '1 Room', value: 1, selected: 'selected'},
      {label: '2 Rooms', value: 2},
      {label: '3 Rooms', value: 3},
      {label: '4 Rooms', value: 4},
      {label: '5+ Rooms', value: 5},
    ],
    adultCount: [
      {label: '1 Adult', value: 1},
      {label: '2 Adults', value: 2, selected: 'selected'},
      {label: '3 Adults', value: 3},
      {label: '4 Adults', value: 4},
      {label: '5 Adults', value: 5},
      {label: '6 Adults', value: 6},
      {label: '7 Adults', value: 7},
      {label: '8 Adults', value: 8},
    ],
    childCount: [
      {label: '0 Children', value: 0},
      {label: '1 Child', value: 1},
      {label: '2 Children', value: 2},
      {label: '3 Children', value: 3},
      {label: '4 Children', value: 4},
      {label: '5 Children', value: 5},
    ],
    childAge: [
      {label: 'Age', value: -1, selected: 'selected'},
      {label: 'IN', value: 0},
      {label: '1', value: 1},
      {label: '2', value: 2},
      {label: '3', value: 3},
      {label: '4', value: 4},
      {label: '5', value: 5},
      {label: '6', value: 6},
      {label: '7', value: 7},
      {label: '8', value: 8},
      {label: '9', value: 9},
      {label: '10', value: 10},
      {label: '11', value: 11},
      {label: '12', value: 12},
      {label: '13', value: 13},
      {label: '14', value: 14},
      {label: '15', value: 15},
      {label: '16', value: 16},
      {label: '17', value: 17},
    ],
  };
  const defaultRoom = {
    adult_count: 2,
    adult_count_options: defaultOptions.adultCount,
    child_count: 0,
    child_count_options: defaultOptions.childCount,
    children: [],
    room_number: function() {
      return this.index + 1;
    }
  };
  const defaultChild = {
    age: -1,
    child_age_options: defaultOptions.childAge,
  };
  const defaultDatepickerOptions = {
      singleDatePicker: true,
      autoApply: true,
      showDropdowns: false,
      opens: 'center',
      minDate: moment(),
      locale: {
        format: dateFormat,
      }
  };
  const postData = {
    currentCulture: 'en-US',
    gsdateformat: 'd',
    gsDestination: '',
    gsLengthOfStay: '',
    gsPromotionCode: '',
    gsDepartureDate: '',
    gsReturnDate: '',
    gsNumberOfAdults: '',
    gsNumberOfTravelers: '',
    gsAge1: '',
    gsAge2: '',
    gsAge3: '',
    gsAge4: '',
    gsAge5: '',
  };

  const BookingEngine = Alg0213.BookingEngine;

  // View state
  const state = {
    room_count_options: defaultOptions.roomCount,
    rooms: [{
      ...defaultRoom,
      index: 0,
      first: true,
    }],
  };

  /**
   * Initialize the widget
   */
  (function init(){
    $roomOptions.hide();
    $departDate.val(defaultStartDate.format(dateFormat));
    $returnDate.val(defaultEndDate.format(dateFormat));
    initDatepickers();
    initTypeahead($inputOrigin, 'origin');
    initTypeahead($inputDestination, 'destination');
    initValidation();
    addEvents();
    makeSticky();
    initOrigin();
  })();


  /**
   * Initialize custom validation rules
   */
  function initValidation(){
    Parsley.addValidator('checkTravelerCount', {
      messages: {
        // eslint-disable-next-line max-len
        en: `Total number of passengers exceeds the passengers limit of ${model.maxTravelers} passengers. Please revise the selection.`
      },
      requirementType: 'integer',
      validateNumber: () => getNumberOfTravelers() <= model.maxTravelers,
    });
  }

  /**
   * Initialize date pickers
   */
  function initDatepickers() {
    const departDatePicker = $departDate.daterangepicker({
      ...defaultDatepickerOptions,
      startDate: defaultStartDate,
    }).data('daterangepicker');

    const returnDatePicker = $returnDate.daterangepicker({
      ...defaultDatepickerOptions,
      startDate: defaultEndDate,
      minDate: defaultStartDate.add(1, 'days'),
    }).data('daterangepicker');

    $departDate.on('apply.daterangepicker', (e) => {
      const start = departDatePicker.startDate;
      returnDatePicker.minDate = start.clone().add(1, 'days');
      if(returnDatePicker.startDate.isSameOrBefore(start)) {
        returnDatePicker.setStartDate(start.clone().add(tripLength, 'days'));
        returnDatePicker.setEndDate(false);
      }
      $returnDate.focus();
    });
  }

  /**
   * Set the origin field 
   * @param {object} 
   */
  function setOrigin(origin) {
    $inputOrigin.typeahead('val', origin.name || '');
  }


  /**
   * Prefill the origin with user location if its not specified
   */
  function initOrigin() {
    if($inputOrigin.val() !== '') {
      return;
    }

    BookingEngine.getUserOrigin().then(setOrigin);
  }


  /**
   * Delegate UI events to their handlers.
   */
  function addEvents() {
    $(document).on('keydown', handleKeypress);
    $this.on('click', `${selector}__close-alert`, resetRoomCount);
    $editBtn.on('click', showRoomOptions);
    $buttonHotelFlight.on('click', showFlightOptions);
    $buttonHotelOnly.on('click', hideFlightOptions);
    $buttonFlightOnly.on('click', hideHotelOptions);
    $form.on('change', handleFormChange);
    $submit.on('click', submitForm);
    $form.on('submit', handleSubmit);
    $buttonGo.on('click', handleGet);
  }


  /**
   * Make the form sticky when scrolled off screen
   */
  function makeSticky() {
    const intersectCallback = ([e]) => {
      const offscreen = (e.boundingClientRect.height + e.boundingClientRect.top) < 0;
      const breakpoint = $.breakpoint('get');
      handleResize(offscreen);
      $form[0].toggleAttribute('stuck', offscreen);
      if (offscreen && ['xs', 'sm'].includes(breakpoint.size)) {
        $form.hide().slideDown();
      }
    };

    new IntersectionObserver( 
      intersectCallback,
      { threshold: [0, 1] }
    ).observe($formPlaceholder[0]);
  }


  /**
   * Keep placeholder size in sync with form size
   */
  function handleResize(stuck) {
    const height = stuck ? $form.outerHeight(true) : 0;
    $formPlaceholder.height(height);
  }


  /**
   * Override default submit
   * @param {*} event
   * @returns
   */
  function handleSubmit(event) {
    event.preventDefault();
    return false;
  }


  /**
   * Send form data via GET
   */
  function handleGet() {
      const data = getFormData();
      BookingEngine.get(data);
  }


  /**
   * Reset the room count field to 1
   */
  function resetRoomCount(){
    $form.find(`${selector}__options_rooms`).val(1).trigger('change');
  }


  /**
   * Submit form when enter key is pressed on submit button.
   * @param {event} event
   */
  function handleKeypress(event) {
    // Capture 'Enter' keypress on the submit button
    if($submit.is($(event.target)) && event.which == 13) {
      submitForm();
    }

    // Capture all 'Enter' keypress
    if(event.which == 13) {
      event.preventDefault();
      return false;
    }
  }


  /**
   * Show origin field and update tabs
   *
   * @param {*} event
   */
  function showFlightOptions(event){
    event.preventDefault();
    BookingEngine.update({
      gsPackage: packageType.flightHotel,
      gsvacationtype: packageType.flightHotel,
    });

    $originColumn.show();
    $buttonHotelFlight.addClass('active');
    $buttonFlightOnly.removeClass('active');
    $buttonHotelOnly.removeClass('active');
    $inputOrigin.prop('required',true);
    $form.removeClass("hide-room-info");
  }


  /**
   * Hide origin field and update tabs
   *
   * @param {*} event
   */
   function hideFlightOptions(event){
    event.preventDefault();
    BookingEngine.update({
      gsPackage: packageType.hotel,
      gsvacationtype: packageType.hotel,
    });
    $originColumn.hide();
    $buttonHotelFlight.removeClass('active');
    $buttonFlightOnly.removeClass('active');
    $buttonHotelOnly.addClass('active');
    $inputOrigin.removeAttr('required');
    $form.removeClass("hide-room-info");
  }

  /**
   * Hide rooms field and update tabs
   *
   * @param {*} event
   */
  function hideHotelOptions(event){
    event.preventDefault();
    resetRoomCount();
    BookingEngine.update({
      gsPackage: packageType.flight,
      gsvacationtype: packageType.flight,
    });
    $originColumn.show();
    $buttonHotelFlight.removeClass('active');
    $buttonHotelOnly.removeClass('active');
    $buttonFlightOnly.addClass('active');
    $inputOrigin.prop('required', true);
    $form.addClass("hide-room-info");
  }

  /**
   *
   * Show room options selections
   *
   * @param {*} event
   */
  function showRoomOptions(event){
    event.preventDefault();
    renderRoomOptions(event);
    $roomOptions.show();
    $editBtn.hide();
  }


  /**
   * Track form state and re-render
   *
   * @param {*} event
   */
  function handleFormChange(event){
    const $el = $(event.target);
    const type = $el.data('type');
    const roomIndex = $el.data('roomIndex');
    const childIndex = $el.data('childIndex');
    const nthOfType = $form.find(`[data-type=${type}]`).index($el);
    const value = Number($el.val());
    const previousState = {...state};

    switch(type) {
      case 'room-count':
        state.rooms = [];
        for(let i = 0; i < value; i++){
          state.rooms[i] = previousState.rooms[i] ?? {...defaultRoom, index: i};
        }
        state.room_count_options = setSelected(defaultOptions.roomCount, value);
        break;

      case 'child-count':
        state.rooms[roomIndex].child_count = value;
        state.rooms[roomIndex].child_count_options = setSelected(
          defaultOptions.childCount,
          value
        );

        state.rooms[roomIndex].children = [];
        for(let i = 0; i < value; i++){
          state.rooms[roomIndex].children[i] =
            previousState.rooms[roomIndex].children[i]
            ?? {...defaultChild, index: i, child_index:i+1, room_index: roomIndex};
        }
        break;

      case 'adult-count':
        state.rooms[roomIndex].adult_count = value;
        state.rooms[roomIndex].adult_count_options = setSelected(
          defaultOptions.adultCount,
          value
        );
        break;

      case 'child-age':
        state.rooms[roomIndex].children[childIndex].age = value;
        // eslint-disable-next-line max-len
        state.rooms[roomIndex].children[childIndex].child_age_options = setSelected(
          defaultOptions.childAge,
          value
        );
        break;
    }

    renderRoomOptions(event);

    if(type){
      // Reset focus on the changed element and validate.
      $form.find(`[data-type=${type}]`).eq(nthOfType).focus()
        .parsley().validate();
    }
  }


  /**
   * Set the selected attribute in the select element view.
   *
   * @param {*} list
   * @param {*} value
   * @returns
   */
  function setSelected (list, value) {
    return list.map(item => {
      option = {...item};
      delete(option.selected);
      if (option.value == value) {
        option.selected = 'selected';
      }
      return option;
    });
  }


  /**
   * Render room option selections.
   *
   * @param {*} e
   */
  function renderRoomOptions(e) {
    const roomOptions = Mustache.render(
      templateRoomOptions,
      state,
      {
        'select-room-count': templateSelectRoomCount,
        'select-adult-count': templateSelectAdultCount,
        'select-child-count': templateSelectChildCount,
        'select-child-age': templateSelectChildAge,
      }
    );

    e.preventDefault();
    $roomOptions.html(roomOptions);
    $roomOptions.find('[data-toggle="tooltip"]').tooltip();
  }


  /**
   * Initialize typeahead fields
   * @returns {Bloodhound}
   */
  function initTypeahead($input, type) {
    const endpoint = `${model.apiUrl}/${type}`;
    const bloodhoundSuggestions = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: endpoint + '?q=%QUERY',
        wildcard: '%QUERY',
        transform: function(response){
          return response.results;
        }
      }
    });

    $input.typeahead({
      hint: true,
      highlight: true,
      minLength: 2,
    },
    {
      name: 'suggestions',
      display: 'name',
      source: bloodhoundSuggestions,
    });

    return bloodhoundSuggestions;
  }


  /**
   * Set the origin field 
   * @param {object} origin
   */
  function setOrigin(origin) {
    $inputOrigin.typeahead('val', origin.name || '');
  }


  /**
   * Get airport code from a location string
   * @param {string} location 
   * @returns string
   */
  function getAirportCode(location) {
    const matches = location.match(/\(([A-Z]{3})\)/);
    return matches[1] ?? '';
  }


  /**
   * Get values for child age fields
   * @param {integer} roomNumber
   * @returns string
   */
  function getChildren(roomNumber) {
    return state.rooms.map(room =>
      room.children[roomNumber - 1] ? room.children[roomNumber - 1].age : ''
    ).join('|');
  }

  /**
   * Get total number of child and adult travelers
   * @returns
   */
  function getNumberOfTravelers() {
    return state.rooms.map(room =>
      room.adult_count + room.child_count
    ).reduce((total, current) => total + current);
  }


  /** 
   * Get the prepared form data, ready to submit to the bookinng engine.
  */
  function getFormData() {
    const gsNumberOfAdults = state.rooms.map(room =>
      room.adult_count
    ).join('|');
    const gsNumberOfTravelers = state.rooms.map(room =>
      room.adult_count + room.child_count
    ).join('|');
    const gsLengthOfStay = moment($returnDate.val(), dateFormat)
      .diff(moment($departDate.val(), dateFormat), 'days');

    const data = {
      ...postData,
      gsNumberOfAdults,
      gsNumberOfTravelers,
      gsLengthOfStay,
      gsDestination: getAirportCode($inputDestination.val()),
      gsDepartureDate: moment($departDate.val(), dateFormat).format(BookingEngine.dateFormat),
      gsReturnDate: moment($returnDate.val(), dateFormat).format(BookingEngine.dateFormat),
      gsAge1: getChildren(1),
      gsAge2: getChildren(2),
      gsAge3: getChildren(3),
      gsAge4: getChildren(4),
      gsAge5: getChildren(5),
    };
    if (data.gsPackage === packageType.flightHotel) {
      data.gsOrigin = getAirportCode($inputOrigin.val());
    }

    return data;
  }


  /**
   *  Submit the form data
   */
  function submitForm() {
    const data = getFormData();
    if($form.parsley().isValid()) {
      BookingEngine.get(data);
    }
  }

});
